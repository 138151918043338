import { createReducer, on } from '@ngrx/store'
import { DisplayLayoutEnum } from '../../models/enums/display-layout.enum'
import * as FLinkingToolActions from './f-linking-tool.actions'
import { FLinkingToolState } from './f-linking-tool.state'

export const initialState: FLinkingToolState = {
   gVOState: {
      isSearchOpen: false,
   },
   mPIState: {
      displayLayout: DisplayLayoutEnum.LIST,
   },
   pSPState: {
      displayLayout: DisplayLayoutEnum.LIST,
   },
}

export const featureLinkingToolReducer = createReducer(
   initialState,
   /* Grant vs orgranisation */
   on(FLinkingToolActions.openGVOSearch, (state) => ({ ...state, gVOState: { ...state.gVOState, isSearchOpen: true } })),
   on(FLinkingToolActions.closeGVOSearch, (state) => ({ ...state, gVOState: { ...state.gVOState, isSearchOpen: false } })),
   on(FLinkingToolActions.searchGVOData, (state) => ({
      ...state,
      gVOState: { ...state.gVOState, data: undefined, isLoading: true, errorMessage: undefined, needReload: false },
   })),
   on(FLinkingToolActions.searchGVODataSuccess, (state, action) => ({
      ...state,
      gVOState: { ...state.gVOState, data: action.data, isLoading: false, needReload: false },
   })),
   on(FLinkingToolActions.searchGVODataFailure, (state, action) => ({
      ...state,
      gVOState: { ...state.gVOState, data: undefined, isLoading: false, errorMessage: action.error.message, needReload: false },
   })),
   on(FLinkingToolActions.downloadGVOData, (state) => ({
      ...state,
      gVOState: { ...state.gVOState, isDownloadLoading: true, errorMessage: undefined },
   })),
   on(FLinkingToolActions.downloadGVODataSuccess, (state) => ({
      ...state,
      gVOState: { ...state.gVOState, isDownloadLoading: false },
   })),
   on(FLinkingToolActions.downloadGVODataFailure, (state, action) => ({
      ...state,
      gVOState: { ...state.gVOState, isDownloadLoading: false, errorMessage: action.error.message },
   })),

   on(
      FLinkingToolActions.addGVOComment,
      FLinkingToolActions.addGVOPartner,
      FLinkingToolActions.addGVOResponsible,
      FLinkingToolActions.addGVOLeadingEntity,
      FLinkingToolActions.addGVOPNOResponsible,
      FLinkingToolActions.deleteGVOComment,
      FLinkingToolActions.deleteGVOPartner,
      FLinkingToolActions.deleteGVOResponsible,
      FLinkingToolActions.deleteGVOLeadingEntity,
      (state) => ({
         ...state,
         gVOState: { ...state.gVOState, needReload: false, isLoading: true },
      })
   ),

   on(
      FLinkingToolActions.addGVOPartnerSuccess,
      FLinkingToolActions.addGVOCommentSuccess,
      FLinkingToolActions.addGVOResponsibleSuccess,
      FLinkingToolActions.addGVOLeadingEntitySuccess,
      FLinkingToolActions.addGVOPNOResponsibleSuccess,
      FLinkingToolActions.deleteGVOCommentSuccess,
      FLinkingToolActions.deleteGVOPartnerSuccess,
      FLinkingToolActions.deleteGVOResponsibleSuccess,
      FLinkingToolActions.deleteGVOLeadingEntitySuccess,

      (state) => ({
         ...state,
         gVOState: { ...state.gVOState, needReload: true },
      })
   ),
   on(
      FLinkingToolActions.addGVOCommentFailure,
      FLinkingToolActions.addGVOPartnerFailure,
      FLinkingToolActions.addGVOResponsibleFailure,
      FLinkingToolActions.addGVOLeadingEntityFailure,
      FLinkingToolActions.addGVOPNOResponsibleFailure,
      FLinkingToolActions.deleteGVOCommentFailure,
      FLinkingToolActions.deleteGVOPartnerFailure,
      FLinkingToolActions.deleteGVOResponsibleFailure,
      FLinkingToolActions.deleteGVOLeadingEntityFailure,

      (state, action) => ({
         ...state,
         gVOState: { ...state.gVOState, needReload: false, isLoading: false, errorMessage: action.error.message },
      })
   ),
   on(FLinkingToolActions.resetGVOState, (state) => ({
      ...state,
      gVOState: { isSearchOpen: false },
   })),

   on(FLinkingToolActions.setGVOSearchFields, (state, action) => ({
      ...state,
      gVOState: { ...state.gVOState, searchFields: action.searchFields },
   })),

   /* Manage proposed ideas */
   on(FLinkingToolActions.setMPIDisplayLayoutList, (state) => ({
      ...state,
      mPIState: { ...state.mPIState, displayLayout: DisplayLayoutEnum.LIST },
   })),
   on(FLinkingToolActions.setMPIDisplayLayoutGrid, (state) => ({
      ...state,
      mPIState: { ...state.mPIState, displayLayout: DisplayLayoutEnum.GRID },
   })),

   on(FLinkingToolActions.setMPISearchFields, (state, action) => ({
      ...state,
      mPIState: { ...state.mPIState, searchFields: action.searchFields },
   })),
   on(FLinkingToolActions.getMPIData, (state) => ({
      ...state,
      mPIState: { ...state.mPIState, data: undefined, isLoading: true, errorMessage: undefined },
   })),
   on(FLinkingToolActions.getMPIDataSuccess, (state, action) => ({
      ...state,
      mPIState: { ...state.mPIState, data: action.data, isLoading: false, needReload: false },
   })),
   on(FLinkingToolActions.getMPIDataFailure, (state, action) => ({
      ...state,
      mPIState: { ...state.mPIState, data: undefined, isLoading: false, errorMessage: action.error.message, needReload: false },
   })),

   on(FLinkingToolActions.markMPIDataAsProcessed, FLinkingToolActions.deleteMPIData, (state) => ({
      ...state,
      mPIState: { ...state.mPIState, needReload: false, isLoading: true },
   })),

   on(FLinkingToolActions.markMPIDataAsProcessedSuccess, FLinkingToolActions.deleteMPIDataSuccess, (state) => ({
      ...state,
      mPIState: { ...state.mPIState, needReload: true },
   })),

   on(FLinkingToolActions.markMPIDataAsProcessedFailure, FLinkingToolActions.deleteMPIDataFailure, (state, action) => ({
      ...state,
      mPIState: { ...state.mPIState, needReload: false, isLoading: false, errorMessage: action.error.message },
   })),
   on(FLinkingToolActions.resetMPIState, (state) => ({
      ...state,
      mPIState: { displayLayout: DisplayLayoutEnum.LIST },
   })),

   /* Projects seeking partners */
   on(FLinkingToolActions.setPSPDisplayLayoutList, (state) => ({
      ...state,
      pSPState: { ...state.pSPState, displayLayout: DisplayLayoutEnum.LIST },
   })),
   on(FLinkingToolActions.setPSPDisplayLayoutGrid, (state) => ({
      ...state,
      pSPState: { ...state.pSPState, displayLayout: DisplayLayoutEnum.GRID },
   })),
   on(FLinkingToolActions.getPSPData, (state) => ({
      ...state,
      pSPState: { ...state.pSPState, data: undefined, isLoading: true, errorMessage: undefined },
   })),
   on(FLinkingToolActions.getPSPDataSuccess, (state, action) => ({
      ...state,
      pSPState: { ...state.pSPState, data: action.data, isLoading: false },
   })),
   on(FLinkingToolActions.getPSPDataFailure, (state, action) => ({
      ...state,
      pSPState: { ...state.pSPState, data: undefined, isLoading: false, errorMessage: action.error.message },
   })),
   on(FLinkingToolActions.resetPSPState, (state) => ({
      ...state,
      pSPState: { displayLayout: DisplayLayoutEnum.LIST },
   }))
)
